.register-input {
    font-size: 24px;
}

@media (max-width: 720px) {
    .register-input {
        font-size: 18px;
    }
}

@media (max-width: 599px) {
    .register-input {
        font-size: 12px;
    }
}