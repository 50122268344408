.privacyTag{
  color: black;
  cursor: pointer;
 
  text-decoration: none;
}
.privacyTag:hover{
  text-decoration: underline;
}
.whiteLogoImg{
  margin-top: 57px;
  width: 200px;
  
}

.footerBGImg{
  width: 193px;
  padding-bottom:"40px" ;
}



@media (min-width: 1560px) and (max-width: 2560px) {
  
.privacyTag{
  font-size: 18px;

}
  .mobileFooterHeight {
      height: 690px;
    }
  
    .footerLinkText button {
      font-size: 3.3rem;
    }

    .footerBGImg{
    
      padding-top:"70px" ;
      padding-bottom:"50px" ;
    }

    .whiteLogoImg{
      margin-top: 57px;
      width: 200px;
      
    }
}

@media (min-width: 750px) and (max-width: 1190px) {
  
.privacyTag{
  font-size: 14px;
}
  .mobileFooterHeight{
    height: 350px;
  }
  .whiteLogoImg{
    margin-top: 57px;
    width: 200px;
    
  }
  .footerLinkText button{
    font-size: 1.2rem;
  }
    .footerBGImg {
    padding-bottom: 50px;
      padding-top:70px ;
    }
}
@media (min-width: 320px) and (max-width: 423px) {
  
.privacyTag{
  font-size: 12px;
}
  .mobileFooterHeight {
    height: 270px;
  }
    .footerBGImg {
      width: 80px;
    }
    .whiteLogoImg{
      margin-top: 25px;
      width: 139px;
      
    }
}

@media (min-width: 424px) and (max-width: 529px) {

  
.privacyTag{
  font-size: 12px;
}
  .mobileFooterHeight {
    height: 270px;
  }
  .whiteLogoImg{
    margin-top: 37px;
    width: 139px;
    
  }
    .footerBGImg {
      width: 80px;
      padding-bottom:"23px" ;
      padding-top:"23px" ;
    }
}

@media (min-width: 530px) and (max-width: 767px) {
  
.privacyTag{
  font-size: 14px;
}
  .mobileFooterHeight {
    height: 310px
    ;
  }
  .footerBGImg {
    width: 90px;
    padding-top:"23px" ;
    padding-bottom:"23px" ;
  }
    .whiteLogoImg{
      margin-top: 40px;
      width: 200px;
      
    }
}

/* Add these styles to your CSS or styled-components */

.footer-container {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars-img {
  width: 100%;
  position: absolute;
  bottom: 2px;
  z-index: 1;
}

.footer-content {
  /* position: relative; */
  z-index: 4;
  width: 100%;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add your link styles here */
}

.text-texturina {
  font-size: 16px;
  /* Adjust font size as needed */
}
.footer-bg-image {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  position: relative;
}

.spacer {
  height: 200px;
  /* Adjust the height to control spacing */
}