.scrollFAQContainer {
  /* height: 80vh; */
  /* width: 80%; */
  overflow-y: scroll;
  z-index: 4;
}

.scrollFAQContainer::-webkit-scrollbar {
  display: none;
}

/* .scrollBox {
    height: 80vh;
    overflow-y: scroll;
    margin-top: 300px; //
    z-index: 5;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

}

.scrollBox::-webkit-scrollbar {
    display: none;
} */

@media (min-width: 320px) and (max-width: 365px) {
  .hamburgerIcon {
    margin-top: -4px;
    border: "1px solid red";
  }
}

@media (min-width: 365px) and (max-width: 400px) {
    .hamburgerIcon {
      margin-top: -2px;
      border: "1px solid red";
    }
  }

  @media (min-width: 470px) and (max-width: 550px) {
    .hamburgerIcon {
      margin-top: 3px;
      border: "1px solid red";
    }
  }

  @media (min-width: 550px) and (max-width: 600px) {
    .hamburgerIcon {
      margin-top: 6px;
      border: "1px solid red";
    }
  }

/* For mobile screens */
@media (max-width: 767px) {
  .responsive-image {
    width: 269px;
    height: auto;
    position: relative;
    top: 67px;
    padding-top: 40px;
   padding-bottom: 40px;

   
  }
  


  .responsive-image-buttons {
    width: 70%;
    height: auto;
  }
  .responsive-image2 {
    width: 80%;
    height: auto;
    margin-top: 7%;
  }
  .responsive-text-banner {
    width: 65%;
    height: 35vh;
  }
  .about-responsive-text-banner {
    width: 65%;
    height: 30vh;
  }
  .orange-banner {
    height: 40vh;
  }
  .is-iphone .register-input {
    font-size: 12px;
    height: 20px !important;
  }
  .is-android .register-input {
    font-size: 12px;
    height: 24px !important;
  }
  /* .form-font{
        font-size: 12px !important;
        margin: 0;
    } */
  /* input[type="text"] {
        border-radius: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
    } */
  /* input[type="text"],
    input[type="email"] {
        -webkit-appearance: none;
        padding: 0 !important;
        margin: 0 !important;
    } */
  .is-android .register-signup-button {
    /* background-color: "#44C0A8"; */
    font-size: 20px;
    /* color: "#fffde7";
        margin-top: "12px";
        cursor: "pointer"; */
    /* padding:2px 25px; */
  }
  .is-iphone .register-signup-button {
    /* background-color: "#44C0A8"; */
    font-size: 20px;
    /* color: "#fffde7";
        margin-top: "12px";
        cursor: "pointer"; */
    padding: 2px 8px;
  }
  .scrollBox {
    /* height: 500px !important; */
    /* overflow-y: scroll !important; */
    margin-top: 40px;
    z-index: 4;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid blue */
  }
}

/* For tablet and desktop screens */
@media (min-width: 768px) {
  .responsive-image {
    width: 489px; 
    height: auto;
    position: relative;
    top: 125px; 
    padding-top: 54px;
   padding-bottom: 55px;

  }
  

  .responsive-image-buttons {
    width: 50%;
    height: auto;
  }
  .responsive-text-banner {
    width: 55%;
    height: 30vh;
  }
  .about-responsive-text-banner {
    width: 55%;
    height: 20vh;
  }
  .responsive-image2 {
    width: 70%;
    height: auto;
    margin-top: 5%;
  }
  .register-input {
    font-size: 20px;
    height: 34px;
  }
  .register-signup-button {
    /* background-color: "#44C0A8"; */
    font-size: 40px;
    /* color: "#fffde7";
        margin-top: "12px";
        cursor: "pointer"; */
    padding: 2px 25px;
  }
  .scrollBox {
    /* height: 80vh;
        overflow-y: scroll !important; */
    /* margin-top: 300px; */
    z-index: 4;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .scrollFAQContainer {
    /* height: 80vh; */
    /* width: 80%; */
    overflow-y: scroll;
    z-index: 4;
  }

  .scrollFAQContainer::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 1024px) {
  /* .responsive-text-banner {
            width: 55%;
            height: 45vh;
    } */
  .about-responsive-text-banner {
    width: 55%;
    height: 35vh;
  }
  /* .register-input {
        font-size: 20px;
        height: 36px;
    } */
  .scrollFAQContainer {
    /* height: 80vh; */
    /* width: 80%; */
    overflow-y: scroll;
    z-index: 4;
  }

  .scrollFAQContainer::-webkit-scrollbar {
    display: none;
  }
}
@media (min-width: 1441px) {
  .responsive-text-banner {
    width: 55%;
    height: 45vh;
  }
  .about-responsive-text-banner {
    width: 55%;
    height: 40vh;
  }
  .register-input {
    font-size: 20px;
    height: 36px;
  }
  .scrollFAQContainer {
    /* height: 80vh; */
    /* width: 80%; */
    overflow-y: scroll;
    z-index: 4;
  }

  .scrollFAQContainer::-webkit-scrollbar {
    display: none;
  }
}
.forSmallScreen{
  padding-top: 4px ;
  padding-bottom: 25px;
}