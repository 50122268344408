.accordionContainer {
  width: 755px;
  margin: 16px auto;
}

.mainAccordion {
  background-color: #FFE8C5 !important;
  color: black !important;
  border: 1px solid #390C06 !important;
  transition: min-height 0.3s ease;
}

.accordionTitle {
  font-family: "Texturina", serif !important;
  font-weight: 600 !important;
  text-align: left !important;
  transition: font-size 0.3s ease, line-height 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointList {
  font-weight: 600;
  text-align: left;
  padding-left: 0px;
  font-family: "Texturina", serif;
}

.MuiAccordionSummary-root {
  min-height: 60px !important;
  height: 60px;
}

.accordionIcon {
  color: black !important;
  font-size: 24px !important;
}

.MuiAccordionDetails-root {
  background-color: #FFE8C5 !important;
  max-height: 300px;
  overflow-y: auto;
  padding-left: 0px !important;
  padding-top: 11px !important;
  padding-bottom: 12px !important;
}

@media (max-width: 499px) {
  .accordionContainer {
    width: 280px;
  }

  .accordionTitle {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }

  .pointList {
    padding-left: 0px;
    font-size: 12px;
  }

  .accordionIcon {
    font-size: 20px !important;
  }

  .MuiAccordionSummary-root {
    min-height: 40px !important;
    height: 40px;
  }
}

@media screen and (min-width: 500px) and (max-width: 719px) {
  .accordionTitle {
    font-size: 12px !important;
    line-height: 22px !important;
  }
  .pointList {
    padding-left: 0px;
    font-size: 12px;
  }
  .accordionContainer {
    width: 400px;
  }
  .MuiAccordionSummary-root {
    min-height: 50px !important;
    height: 50px;
  }
}

@media screen and (min-width: 720px) {
  .accordionTitle {
    font-size: 24px !important;
    line-height: 28px !important;
  }
  .pointList {
    padding-left: 0px;
    font-weight: 600;
    font-size: 20px;
  }
}

@media screen and (min-width: 500px) and (max-width: 820px) {
  .accordionContainer {
    width: 400px;
  }
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}

.Mui-expanded .accordionTitle {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  line-height: normal;
}