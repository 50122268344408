html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.font-modesto-condensed {
    font-family: 'modesto-condensed' !important;
}

.font-texturina {
    font-family: 'Texturina' !important;
}
.hamburger {
   
    background-size: cover;
    background-repeat: no-repeat;
    /* for second phase comment below code as Ham content increase and social links are not visible */
        background-attachment: fixed;
        /* min-height: 100vh; */
    overflow-y: scroll;
}
.rotate {
    transform: rotate(180deg);
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-center {
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.z-index-3 {
    z-index: 3;
}

.z-index-4 {
    z-index: 4;
}

.z-index-5 {
    z-index: 5;
}

.btn-text {
    color: black;
    text-decoration: none !important;
}

@media (max-width: 424px) {
    .font-relative {
        font-size: 8px !important;
    }

    .top-10 {
        top: 10%;
    }

    .top-5 {
        top: 5%;
    }

    .bottom-2 {
        bottom: 2%;
    }

    .homeMargin {
        margin-bottom: 0px;
    }

    .relative-width {
        width: 50%;
    }

    .heading-relative {
        font-size: 25px !important;
    }

    .small-footer {
        visibility: visible;
        height: 300px;
    }

    .registerTop {
        top: 0%;
    }

    .homeTop {
        top: 70px;
    }

    .gogoDollBanner {
        width: 300px;
        position: relative;
        z-index: 3;
        margin-top: 100px;
        margin-bottom: -220px;
    }

    .ticketTop {
        top: 20%;
    }

    .relative-banner-width-height {
        width: 60%;
        height: 300px;
    }
}

@media (min-width: 425px) and (max-width: 767px) {
    .font-relative {
        font-size: 8px !important;
    }

    .homeMargin {
        margin-bottom: 0px;
    }

    .relative-width {
        width: 50%;
    }

    .heading-relative {
        font-size: 30px !important;
    }

    .small-footer {
        visibility: visible;
        height: 300px;
    }

    .live-logo {
        width: 80px;
    }

    .large-footer {
        visibility: visible;
    }

    .small-footer {
        visibility: hidden;
    }

    .homeTop {
        top: 4%;
    }

    .ticketTop {
        top: 20%;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .font-relative {
        font-size: 12px !important;
    }

    .relative-width {
        width: 45%;
    }

    .heading-relative {
        font-size: 50px !important;
    }

    .large-footer {
        visibility: visible;
    }

    .small-footer {
        visibility: hidden;
    }

    .homeTop {
        top: 7%;
    }
}

@media (min-width: 1024px) and (max-width: 1400px) {
    .font-relative {
        font-size: 20px !important;
    }

    .relative-width {
        width: 50%;
    }

    .large-footer {
        visibility: visible;
    }

    .small-footer {
        visibility: hidden;
    }
}

@media (min-width: 1401px) {
    .large-footer {
        visibility: visible;
    }

    .small-footer {
        visibility: hidden;
    }

    .font-relative {
        font-size: 25px !important;
    }

    .relative-banner-width-height {
        width: 700px;
    }
}